class StaticStorage {
    static instance;
    static data;
    constructor() {
        StaticStorage.data = {}
    }
    setItem(key, value) {
        StaticStorage.data = {
            ...StaticStorage.data,
            [key]: value
        }
    }
    getItem(key) {
        return StaticStorage.data[key];
    }
    removeItem(key) {
        if (Object.keys(StaticStorage.data)?.includes(key)) {
            delete StaticStorage.data[key];
        }
    }
    getAll() {
        return StaticStorage.data;
    }
    setAll(data) {
        StaticStorage.data = data;
    }
    static getInstance(){
        if (!StaticStorage.instance)
            StaticStorage.instance = new StaticStorage();
        return StaticStorage.instance;
    }
}
export default StaticStorage.getInstance;