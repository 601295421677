import axios from "axios";
import React, { useState, useEffect } from "react";
import { Loader } from "./Home";
import StaticStorage from './preferences/StaticStorage';
import AxiosRequest from "./helpers/AxiosRequest";
import { useCookies } from 'react-cookie';
import BaseComponent from "./BaseComponent";
import Helpers from "./helpers/Helpers";
import BackToAppButton from "./BackToAppButton";

export default function Redirection(props) {
    const [docsStatus, setDocsStatus] = useState(null);    
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [cookies, setCookie, removeCookie] = useCookies(['upsp']);
    const language = StaticStorage().getItem("lang");
    let isArabic = language === 'ar';
    useEffect(() => {
        switch(status){
            case 'loading': case 'uploadingDocs': setLoading(true);break;
            default: setLoading(false);break;
        }
    }, [status]);
    useEffect(() => {
        setStatus("loading");
        
        var formData = getQueryString();

        let userEmail = StaticStorage().getItem('email');
        let isLastSignature = StaticStorage().getItem('is_last_signature');
        //Get module preferences
        AxiosRequest.getModulePreferences('uaepass')
            .then(response => {
                if (response) {
                    let signees = JSON.parse(response?.signees);
                    if (signees?.length > 0) {
                        let signeeData = signees.find(signature => signature.email === userEmail);
                        if (signeeData) {
                            let signProcessData = signeeData.uaepass_data;
                            if (signProcessData) {
                                //Get document Id
                                let documentId = signProcessData.documentId;
                                if (documentId?.length > 0) {                                                                                                            
                                    formData.append("documentId", documentId);                                    

                                    formData.append("isLastSignature", isLastSignature);

                                    formData = Helpers.initiateFormData(formData);
                                    
                                    axios.post("/signature/uaepass/download", formData, {
                                        responseType: 'arraybuffer'
                                    })
                                        .then(response => {                                            
                                            let file = response.data;
                                            if (file) {
                                                //Upload signed doc to App4Legal                                    
                                                setStatus('uploadingDocs');
                                                let documents = StaticStorage().getItem('documents');
                                                let firstDoc = documents[0];
                                                let extension = 'pdf';
                                                let docName = firstDoc?.name + `_signed.${extension}`;
                                                let lineage = firstDoc.parent_lineage;                                                 
                                                var blob = new Blob([file], {type: 'application/octet-stream'});
                                                var fileObj = new File([blob], docName, {type: 'application/pdf'});                                                                                                
                                                AxiosRequest.uploadSignedFile(fileObj, lineage)
                                                    .then(response => {
                                                        let signerProcessId = signProcessData.processId
                                                        formData.append('signerProcessId', signerProcessId);
                                                        //Delete signer process from UAE PASS
                                                        axios.post("/signature/uaepass/delete", formData)
                                                        .then(response => {
                                                            //Document deleted
                                                            handleSuccessAndCloseApp();
                                                        })
                                                        .catch(error => {
                                                            //Document not deleted
                                                            handleSuccessAndCloseApp();
                                                        })                                                       
                                                    })
                                                    .catch(error => {
                                                        setLoading(false);
                                                        setDocsStatus("0");
                                                        console.log(error);
                                                        if (error?.message) {
                                                            setStatus(error.message)
                                                        }
                                                        else {
                                                            setStatus('error');
                                                        }
                                                    })
                                            }
                                        })
                                        .catch(error => {
                                            let errorCode = Helpers.arraybufferToString(error.response.data);
                                            handleExceptionCode(errorCode);                                            
                                        })
                                }
                                else {
                                    setStatus("invalidUrl");
                                }
                            }
                        }
                        else {
                            setStatus("notFound");
                        }
                    }
                    else {
                        setStatus("error");
                    }
                }
            })
            .catch(error => {
                console.error(error);
                setStatus("error");
            })
    }, []);  
    const handleSuccessAndCloseApp = () => {
        setLoading(false);
        setDocsStatus("1");
        removeCookie("upsp");
        setTimeout(() => {
            window.top?.opener?.postMessage({
                is_app_closed: true
            }, "*");
            window.top?.close();
        }, 5000);
    }
    const handleExceptionCode = (errorCode) => {
        switch(errorCode){
            case 'canceled':{
                //User cancel the signing
                setStatus("userCancelProcess");
            }break;
            case 'failed': case 'failed_documents':{
                //Document signing failed
                setStatus("documentSigningFailed");
            }break;            
        }        
    }  
    const getQueryString = () => {
        var searchpar = new URLSearchParams(window.location.search);
        let formData = new FormData();
        for (var pair of searchpar.entries()) {
            formData.append(pair[0], pair[1]);
        }
        return formData;
    }
    const getDocsStatus = () => {
        switch (docsStatus) {
            case '1': return AxiosRequest.getStatusText("operationDone");            
            case '0': return AxiosRequest.getStatusText("docsUploadError");
        }
    }
    const getDocsStatusIcon = () => {
        switch (docsStatus) {
            case '1': return <div class="o-circle c-container__circle o-circle__sign--success">
                <div class="o-circle__sign"></div>
            </div>;
            case '0': return <div class="o-circle c-container__circle o-circle__sign--failure">
                <div class="o-circle__sign"></div>
            </div>;
            case '3': case '2': return "";
        }
    }
    const getStatusText = (text = null) => {
        return AxiosRequest.getStatusText(text ?? status);
    }    
    return (
        <BaseComponent>
            {docsStatus && <section class="c-container">{getDocsStatusIcon()}</section>}
            {docsStatus && <div><p style={{direction: isArabic ? 'rtl' : 'ltr'}}>{getDocsStatus()}</p></div>}
            {loading && <Loader />}
            {!docsStatus && <p style={{direction: isArabic ? 'rtl' : 'ltr'}}>{getStatusText()}</p>}
            {!loading && docsStatus !== "1" && <BackToAppButton/>}
        </BaseComponent>        
    );
}