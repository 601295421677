import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { auxButtonColor } from "../App";
import { BallTriangle } from 'react-loader-spinner';
import './FrameStyle.css';
import AxiosRequest from "./helpers/AxiosRequest";
import StaticStorage from "./preferences/StaticStorage";
import Helpers from "./helpers/Helpers";
import PDFMerger from 'pdf-merger-js/browser';
import BaseComponent from "./BaseComponent";
import BackToAppButton from "./BackToAppButton";
let signatories = [];
let signeeSignature;
let previewDocument;
export default function Home(props) {    
    const [status, setStatus] = useState(null);    
    const language = StaticStorage().getItem("lang");
    let isArabic = language === 'ar';
    useEffect(() => {
        setStatus("gettingReady");
        let userEmail = StaticStorage().getItem('email');
        if (userEmail) {
            //Get integration URL for the current user based on his email
            //and based on the data saved in the module preferences
            AxiosRequest.getModulePreferences('uaepass')
                .then(response => {
                    if (response) {                       
                        previewDocument = response?.previewDocument;
                        let signees = JSON.parse(response?.signees);                        
                        if (signees?.length > 0) {
                            signatories = signees;
                            let signeeData = signees.find(signee => signee.email === userEmail);
                            if (signeeData) {
                                let signProcessData = signeeData.uaepass_data;
                                if (signProcessData) {
                                    //Check process status
                                    checkProcessStatus(signProcessData);                                    
                                }
                                else {
                                    //No signer process initialized
                                    //Check if signee has at least one signature
                                    let firstSignature = signeeData?.signature?.pages?.find(e => typeof e !== 'undefined');
                                    if(firstSignature){
                                        signeeSignature = firstSignature;
                                        //Register new one by calling the API                                                                                
                                        initiateWorkflow();
                                    }
                                    else{
                                        //No signatures for this signee
                                        setStatus("signatureNotFound");
                                    }                                    
                                }
                            }
                            else {
                                setStatus("notFound");
                            }
                        }
                        else {
                            setStatus("notFound");
                        }
                    }
                })
                .catch(error => {
                    errorHandler(error);
                })
        }
    }, [])
    const initiateWorkflow = async () => {

        setStatus("gettingReady");

        //Get document content
        let documents = StaticStorage().getItem("documents");

        if (documents) {
            let responses = documents.map(document => AxiosRequest.getFileInfo(document.id, true));
            Promise.all(responses)
                .then(async response => {
                    let nonPDFDocs = response.filter(doc => doc.extension !== "pdf");
                    let pdfDocs = response.filter(doc => doc.extension === "pdf");
                    let convertedDocs = [];
                    if (nonPDFDocs.length > 0) {
                        //Convert doc to pdf
                        for (var i = 0; i < nonPDFDocs.length; i++) {
                            let nonPdfDoc = nonPDFDocs[i];
                            try {
                                let convertedDoc = await AxiosRequest.previewDocument(nonPdfDoc);
                                convertedDocs.push(convertedDoc);
                            }
                            catch (err) {
                                console.log(err);
                            }
                        }
                    }
                    let pdfDocsPromise = pdfDocs.map(pdfDoc => AxiosRequest.getFileContent(pdfDoc.id, true));
                    Promise.all(pdfDocsPromise)
                        .then(contentResponse => {
                            contentResponse = contentResponse.map((contentResp, index) => {
                                let originalDocumentName = documents.find(doc => String(doc.id) === String(pdfDocs[index].id))?.name;
                                return ({ ...pdfDocs[index], ...contentResp, name: originalDocumentName })
                            });
                            let allDocsContent = [...contentResponse, ...convertedDocs];
                            mergeDocuments(allDocsContent);
                        })
                })
                .catch(error => {
                    setStatus("error");
                })
        }
        else {
            setStatus("notFound");
            return;
        }
    }
    const mergeDocuments = async (documents) => {
        if (documents?.length > 1) {
            //Merge PDFs in one PDF
            const merger = new PDFMerger();

            for (const doc of documents) {
                let blobPDF = Helpers.convertBase64ToBlob(doc.content);
                await merger.add(blobPDF)
            }

            const mergedPdf = await merger.saveAsBlob();
            let mergedBase64Pdf = await Helpers.blobToBase64(mergedPdf);
            mergedBase64Pdf = mergedBase64Pdf.replace("data:application/pdf;base64,", "");
            let firstDocName = documents[0]?.name;
            sendDocuments(mergedBase64Pdf, firstDocName);
        }
        else {
            let docName = documents[0]?.name;
            let docContent = documents[0]?.content;
            sendDocuments(docContent, docName);
        }
    }
    const checkProcessStatus = (processData) => {                
        let processId = processData?.processId;   
        let formData = new FormData();
        
        formData = Helpers.initiateFormData(formData);            

        formData.append("signerProcessId", processId);

        //Call Post Request to UAE PASS app
        axios.post('/signature/uaepass/signerProcessResult', formData)
            .then(response => {
                //Process not started yet
                //Continue to UAE PASS   
                processGettingSigneeLink(processData);            
            })
            .catch(error => {
                let status = error.response.data;                
                switch(status){
                    case 'canceled':{
                        //User canceled the process
                        setStatus('userCancelProcess');
                    }break;
                    case 'finished':{
                        //Process had finished
                        //Download the document
                        window.location.href = `/redirection?status=${status}`;
                    }break;
                    case 'noData':{
                        //Continue
                        processGettingSigneeLink(processData);            
                    }break;
                    case 'gone': case 'failed':{
                        //Process failed
                        setStatus('documentSigningFailed');                        
                    }break;                    
                    default:{
                        errorHandler(status);
                    }
                }
            })     
    }
    const sendDocuments = (docContent, docName) => {
        let userEmail = StaticStorage().getItem('email');
        let signeeData = signatories.find(signee => signee.email === userEmail);                
        var indexOFSignee = signatories.indexOf(signeeData);
        if (signeeSignature) {
            let buffer = Helpers.base64ToArrayBuffer(docContent);
            const blob = new Blob([buffer], { type: 'application/pdf' });
            let file = new File([blob], docName + '.pdf', { type: 'application/pdf' });

            let formData = new FormData();

            formData.append('file', file);
            let language = StaticStorage().getItem("lang");
            formData.append('locale', language);

            formData.append(`signature[][email]`, userEmail);
            formData.append(`signature[][page]`, signeeSignature.number);
            formData.append(`signature[][coordinates][x]`, signeeSignature.coordinates.x);
            formData.append(`signature[][coordinates][y]`, signeeSignature.coordinates.y);

            formData = Helpers.initiateFormData(formData);            

            //Call Post Request to UAE PASS app
            axios.post('/signature/uaepass/init', formData)
                .then(response => {
                    let data = response.data;
                    if (data) {
                        //Modify data in module preferences 
                        if (data?.success) {
                            let signProcessResponse = data?.success;
                            signatories[indexOFSignee] = {
                                ...signatories[indexOFSignee],
                                uaepass_data: signProcessResponse
                            }
                            let sigtrs = {
                                signees: JSON.stringify(signatories),
                                previewDocument: previewDocument
                            }
                            AxiosRequest.setModulePreferences('uaepass', sigtrs)
                                .then(response => {
                                    if (response) {
                                        processGettingSigneeLink(signProcessResponse);
                                    }
                                })
                                .catch(error => {
                                    errorHandler(error);
                                })
                        }
                        else {
                            errorHandler('error');
                        }
                    }
                })
                .catch(error => {
                    errorHandler(error);
                })
        }
    }
    const errorHandler = (error) => {
        console.error(error);
        setStatus('error');
    }
    const processGettingSigneeLink = (signProcessData) => {
        //Signee had a registered sign process
        //Proceed with the saved link to sign
        let signUrl = signProcessData.signLink;
        if (signUrl?.length > 0) {
            if (Helpers.isValidUrl(signUrl)) {
                //redirect to the integration URL                                              
                redirectUserTouaepassPortal(signUrl)                
            }
            else {
                setStatus("invalidUrl");
            }
        }
        else {
            setStatus("invalidUrl");
        }
    }    
    const redirectUserTouaepassPortal = (signURL) => {
        if (signURL) {
            Helpers.removeCloseCheck();
            window.location.href = signURL;
        }
        else {
            setStatus('error');
        }
    }
    const getStatusText = (text = null) => {
        return AxiosRequest.getStatusText(text ?? status);
    }        
    const startOverProcess = async (event) => {
        event?.preventDefault();
        event?.stopPropagation();
        initiateWorkflow();
    }
    const getTryAgainCases = () => {
        return status === 'documentSigningFailed' ||
        status === 'userCancelProcess';
    }    
    return (
        <BaseComponent logout>
            {!getTryAgainCases() && <Loader />}
            {<p style={{ direction: isArabic ? 'rtl' : 'ltr' }}>{getStatusText()}</p>}
            {getTryAgainCases() && <div className="list">
                <button style={{ backgroundColor: 'beige' }} onClick={startOverProcess}>{getStatusText("startOver")}</button>
                <BackToAppButton />
            </div>
            }
        </BaseComponent>        
    );
}
export const Loader = (props) => {
    return (<BallTriangle
        height={50}
        width={50}
        radius={5}
        color={auxButtonColor}
        ariaLabel="ball-triangle-loading"
        wrapperClass={{}}
        wrapperStyle=""
        visible={true}
    />)
}