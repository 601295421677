import axios, { AxiosResponse } from "axios";
import { getText } from "../preferences/Localization";
import References from "./References";
import StaticStorage from "../preferences/StaticStorage";
export class RequestHeaders {
    url;
    userKey;
    accessToken;
    apiKey;
    version;
    constructor(apiVersion = 2) {
        this.url = StaticStorage().getItem("api_base_url");
        this.accessToken = StaticStorage().getItem("access_token");
        this.apiKey = StaticStorage().getItem("api_key");
        this.version = apiVersion;
    };
    headers() {
        if (this.version === 1) {
            return ({ ...apiHeaders, "api_key": this.apiKey })
        }
        else {
            return ({ ...apiHeaders, "Authorization": `Bearer ${this.accessToken}` })
        }
    };
};

const apiHeaders = {
    "Content-type": "application/x-www-form-urlencoded",
    "x-api-channel": "360-docs"
};
export default class AxiosRequest {
    static instance = axios.create({
        validateStatus: (status) => {
            return status >= 200 && status < 400
        }
    })
    static get = async (api, apiVersion = 2) => {
        const reqHeader = new RequestHeaders(apiVersion);
        const header = reqHeader.headers();
        return new Promise((resolve, reject) => {
            const baseUrl = new RequestHeaders().url;
            const fullUrl = baseUrl + api;
            AxiosRequest.instance.get(fullUrl, {
                headers: header
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        })
    };
    static post = async (api, dataToPost) => {
        const reqHeader = new RequestHeaders();
        const header = reqHeader.headers();
        let response;
        try {
            var formattedDataToPost = new FormData();
            for (var key in dataToPost) {
                formattedDataToPost.append(key, dataToPost[key]);
            }
            return new Promise((resolve, reject) => {
                const baseUrl = new RequestHeaders().url;
                const fullUrl = baseUrl + api;
                AxiosRequest.instance.post(fullUrl, formattedDataToPost, {
                    headers: header,
                })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        } catch (e) {
            console.error("Error post request - App4Legal 360 Docs:" + JSON.stringify(e.message));
        }
        return response;
    };
    static getModuleInfo = () => {
        const module = StaticStorage().getItem('module');
        const moduleId = StaticStorage().getItem('module_record_id');
        const apiChannel = StaticStorage().getItem('api_channel');
        const signatureId = StaticStorage().getItem('signature_id');
        const signeeType = StaticStorage().getItem('signee_type');
        const isSignedDocument = StaticStorage().getItem('is_signed_document');
        return {
            module: module,
            id: moduleId,
            channel: apiChannel ?? "core",
            signatureId: signatureId,
            signeeType: signeeType,
            isSignedDoc: isSignedDocument
        }
    }
    static setAppDirection = () => {
        let language = StaticStorage().getItem("lang");
        let direction = language === 'ar' ? 'rtl' : 'ltr';
        document?.body.setAttribute("dir", direction);
        document?.getElementById("root").setAttribute('dir', direction);
    }
    static getParentIdFromLineage(lineage, row = null) {
        try {
            if (lineage?.includes('/')) {
                const lineageArray = lineage?.split('/');
                return lineageArray[lineageArray.length - 1];
            }
            else {
                if (lineage?.includes('\\')) {
                    const lineageArray = lineage?.split('\\');
                    return lineageArray[lineageArray.length - 1];
                }
                else {
                    if (row) {
                        if (row.parent) {
                            return row.parent;
                        }
                    }
                    else {
                        return lineage;
                    }
                }
            }
        }
        catch (err) {
            console.error('Error fetching lineage - HELPERS: ' + JSON.stringify(err));
        }
    }
    static getUrlPar = (sParam) => {
        try {
            var sPageURL = decodeURIComponent(window.location.search.substring(1)),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;
            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');
                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? '' : sParameterName[1];
                }
            }
        } catch (e) {
            return '';
        }
    }
    static b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    static getStatusText = (status) => {
        return getText(status) ?? "";
    }
    static getFileInfo = async (id, isLatestVersion = false) => {
        const moduleInfo = this.getModuleInfo();
        if (moduleInfo?.module) {
            return await new Promise((resolve, reject) => {
                var url;

                //V2
                url = References.getSignedDocumentInfo(moduleInfo.channel) + id;
                url += `?module=${moduleInfo.module}`;
                url += '&with_download_url=1';
                if (isLatestVersion) {
                    url += '&is_latest_version=1';
                }
                url += `&signature_id=${moduleInfo.signatureId}`;
                url += `&signee_type=${moduleInfo.signeeType}`;
                AxiosRequest.get(url)
                    .then(response => {
                        if (response?.status === 200) {
                            //Call Succeeded                                 
                            resolve(response?.data?.data);
                        }
                        else {
                            //Call failed
                            reject(response.status);
                        }
                    })
                    .catch(error => {
                        reject(error?.response);
                    })
            })
        }
    }
    static getContractInfo = async () => {
        const moduleInfo = this.getModuleInfo();
        if (moduleInfo?.id) {
            return await new Promise((resolve, reject) => {
                var url;
                //V2
                url = References.editContractApi + moduleInfo?.id;
                AxiosRequest.get(url, 1)
                    .then(response => {
                        if (response?.status === 200) {
                            //Call Succeeded                                 
                            resolve(response?.data?.success?.data?.contract);
                        }
                        else {
                            //Call failed
                            reject(response.status);
                        }
                    })
                    .catch(error => {
                        reject(error?.response);
                    })
            })
        }
    }
    static getFileContent = async (id, isLatestVersion = false) => {
        const moduleInfo = this.getModuleInfo();
        if (moduleInfo?.module) {
            return await new Promise((resolve, reject) => {
                var url;

                //V2                     
                url = References.getSignedDocumentContent(moduleInfo.channel) + id;
                url += `?module=${moduleInfo.module}`;
                if (isLatestVersion) {
                    url += `&is_latest_version=1`;
                }
                url += `&signature_id=${moduleInfo.signatureId}`;
                url += `&signee_type=${moduleInfo.signeeType}`;
                AxiosRequest.get(url)
                    .then(response => {
                        if (response?.status === 200) {
                            //Call Succeeded                                 
                            resolve(response?.data);
                        }
                        else {
                            //Call failed
                            reject(response);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            })
        }
    }
    static getDocumentContent = async (id) => {
        const moduleInfo = this.getModuleInfo();
        if (moduleInfo?.module) {
            return await new Promise((resolve, reject) => {
                var url;

                //V2                     
                url = References.getDocumentContent(moduleInfo.channel) + id;
                url += `?module=${moduleInfo.module}`;
                url += `&is_latest_version=1`;

                AxiosRequest.get(url)
                    .then(response => {
                        if (response?.status === 200) {
                            //Call Succeeded                                 
                            resolve(response?.data);
                        }
                        else {
                            //Call failed
                            reject(response);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            })
        }
    }
    static previewDocument = async (row) => {
        const moduleInfo = this.getModuleInfo();
        if (moduleInfo?.module) {
            return await new Promise((resolve, reject) => {
                var url = References.previewSignedDocument(moduleInfo.channel) + row?.id;
                url += `?module=${moduleInfo.module}`;
                url += '&return_base64=1';
                url += `&signature_id=${moduleInfo.signatureId}`;
                url += `&signee_type=${moduleInfo.signeeType}`;
                AxiosRequest.get(url)
                    .then(response => {
                        if (response?.status === 200) {
                            //Call Succeeded
                            const data = response.data;
                            if (data) {
                                if (data?.content) {
                                    resolve(data);
                                }
                                else {
                                    reject(data);
                                }
                            }
                        }
                        else {
                            //Call failed
                            reject(response);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        }
    }
    static uploadSignedFile = async (file, parentId) => {
        const moduleInfo = this.getModuleInfo();
        if (moduleInfo?.module) {
            return await new Promise((resolve, reject) => {
                var url;
                var dataToPost;
                url = References.uploadSignedFile(moduleInfo.channel);
                if (moduleInfo.id && moduleInfo.module !== 'doc') {
                    dataToPost = {
                        module: moduleInfo.module,
                        module_record_id: moduleInfo.id
                    }
                }
                else {
                    dataToPost = {
                        module: moduleInfo.module,
                    }
                }
                if (parentId !== 'undefined' && parentId) {
                    parentId = this.getParentIdFromLineage(parentId);
                    dataToPost = {
                        ...dataToPost,
                        parent: parentId
                    }
                }
                dataToPost = {
                    ...dataToPost,
                    signature_id: moduleInfo.signatureId,
                    signee_type: moduleInfo.signeeType,
                    file: file
                }
                AxiosRequest.post(url, dataToPost)
                    .then(response => {
                        if (response?.status === 200) {
                            //Call Succeeded
                            const data = response.data;
                            if (data) {
                                if (data?.data) {
                                    resolve(data?.data);
                                }
                            }
                        }
                        else {
                            //Call failed
                            reject(response.status);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data) {
                            reject(error?.response?.data);
                        }
                        else {
                            reject(error?.message);
                        }
                    })
            })
        }
    }
    static uploadFile = async (file, parentId) => {
        const moduleInfo = this.getModuleInfo();
        if (moduleInfo?.module) {
            return await new Promise((resolve, reject) => {
                var url;
                var dataToPost;
                url = References.uploadFile(moduleInfo.channel);
                if (moduleInfo.id && moduleInfo.module !== "doc") {
                    dataToPost = {
                        module: moduleInfo.module,
                        module_record_id: moduleInfo.id
                    }
                }
                else {
                    dataToPost = {
                        module: moduleInfo.module,
                    }
                }
                if (parentId !== 'undefined' && parentId) {
                    dataToPost = {
                        ...dataToPost,
                        parent: parentId
                    }
                }
                dataToPost = {
                    ...dataToPost,
                    file: file
                }
                AxiosRequest.post(url, dataToPost)
                    .then(response => {
                        if (response?.status === 200) {
                            //Call Succeeded
                            const data = response.data;
                            if (data) {
                                if (data?.data) {
                                    resolve(data?.data);
                                }
                            }
                        }
                        else {
                            //Call failed
                            reject(response.status);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data) {
                            reject(error?.response?.data);
                        }
                        else {
                            reject(error?.message);
                        }
                    })
            })
        }
    }
    static getModulePreferences = async (integration) => {
        try {
            const module = AxiosRequest.getModuleInfo();
            if (!module)
                return null;
            let url = References.modulePreferencesGet(module.channel);
            url += `?integration=${integration}`;
            url += `&module=${module.module}`;
            if (module.module !== "doc") {
                url += `&module_record_id=${module.id}`;
            }
            const response = await AxiosRequest.get(url);
            switch (response.status) {
                case 200: {
                    //Integration maybe exists
                    const data = response.data;
                    if (data) {
                        if (Array.isArray(data.data)) {
                            return null;
                        }
                        else {
                            let integrationData = data?.data[integration];
                            if (integrationData) {
                                //Check if module in data
                                let moduleData = integrationData[module.module];
                                if (moduleData) {
                                    //Check module id in data
                                    let moduleIdData = moduleData[module.id];
                                    if (moduleIdData) {
                                        return moduleIdData;
                                    }
                                }
                            }
                        }
                    }
                } break;
                case 404: case 422: case 400: {
                    //Integration not found Or missing
                } break;
            }
        }
        catch (err) {
            console.error(err);
            throw err;
        }
        throw null;
    }
    static setModulePreferences = async (integration, data) => {
        try {
            const module = AxiosRequest.getModuleInfo();
            if (!module)
                return null;
            let url = References.modulePreferencesSet(module.channel);
            var dataToPost = {
                integration: integration,
                module: module.module,
                module_record_id: module.id ? module.id : 1,
                ...data
            }
            const response = await AxiosRequest.post(url, dataToPost);
            switch (response.status) {
                case 200: {
                    //Integration maybe exists
                    return true
                } break;
                case 404: case 422: case 400: {
                    //Integration not found Or missing
                } break;
            }
        }
        catch (err) {
            console.error(err);
            throw err;
        }
        throw null;
    }
    static saveUserPreferences = async (data) => {
        const moduleInfo = this.getModuleInfo();
        if (data) {
            return await new Promise((resolve, reject) => {
                let url = References.userPreferences(moduleInfo.channel);
                let dataToPost = {
                    'uaepass': JSON.stringify(data)
                }
                AxiosRequest.post(url, dataToPost, 2)
                    .then(response => {
                        if (response?.status === 200) {
                            //Call Succeeded                            
                            resolve(true);
                        }
                        else {
                            //Call failed
                            reject(response);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        }
    }
    static getUserPreferences = async () => {
        const moduleInfo = this.getModuleInfo();
        return await new Promise((resolve, reject) => {
            let url = References.userPreferences(moduleInfo.channel) + '/uaepass';
            AxiosRequest.get(url, null, 2)
                .then(response => {
                    if (response?.status === 200) {
                        //Call Succeeded                            
                        resolve(response.data?.data);
                    }
                    else {
                        //Call failed
                        reject(response);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }
    static deleteUserPreferences = async () => {
        const moduleInfo = this.getModuleInfo();
        return await new Promise((resolve, reject) => {
            let url = References.userPreferences(moduleInfo.channel) + '/uaepass';
            AxiosRequest.delete(url, 2)
                .then(response => {
                    if (response?.status === 200) {
                        //Call Succeeded                            
                        resolve(true);
                    }
                    else {
                        //Call failed
                        reject(response);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }
}