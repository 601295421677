import React from "react";
import AxiosRequest from "./helpers/AxiosRequest";
import '../custom.css';
import Helpers from "./helpers/Helpers";
import metadata from "../metadata.json";

export default function BackToAppButton() {    
    const getStatusText = (text) => {
        return AxiosRequest.getStatusText(text);
    }
    const handleBackToApp = async (event) => {
        event?.preventDefault();
        event?.stopPropagation();
        Helpers.backToApplication();
    }
    return (
        <button onClick={handleBackToApp}>{getStatusText("backTo")}&nbsp;<span style={{fontWeight: 500}}>{metadata.appName}</span></button>         
    );
}