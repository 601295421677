import React from "react";
import { Loader } from "./Home";
import Toolbar from "./Toolbar";
import '../custom.css';
import metadata from "../metadata.json";

export default function BaseComponent(props) {     
    return (
        <div id="ParentContainer">
            <div id="AppContainer">
                <Toolbar logout={props.logout}/>
                <div id="Container">
                    {props.loading ? <Loader /> :
                        props.children
                    }
                </div>
            </div>
            <div id="footer">
                v.<span>{String(metadata.appVersion)}</span>
            </div>
        </div>        
    );
}