import App4LegalIcon from "../assets/app4legal-logo.png";
import LexzurIcon from "../assets/lexzur-icon-white.png";
import UAEPASSIcon from "../assets/UAEPASS-logo.svg";
import '../custom.css';
import Helpers from "./helpers/Helpers";
import Logout from "./SignOut";
export default function Toolbar(props) {    
    return (
        <div id="Toolbar">
            <div style={{flexGrow: 1}}/>
            <img id="company-logo" src={Helpers.isRebranded() ? LexzurIcon : App4LegalIcon} alt="company-logo" />
            <div id="Vert-Divider"></div>
            <img id="UAEPASSLogo" alt="UAEPASS-logo" src={UAEPASSIcon} />
            <div style={{flexGrow: props.logout ? 0.8 : 1, display: 'flex', justifyContent: 'end'}}>
                {props.logout && <Logout />}
            </div>            
        </div>)
}