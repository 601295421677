import React, { useState } from "react";
import StaticStorage from './preferences/StaticStorage';
import AxiosRequest from "./helpers/AxiosRequest";
import '../custom.css';
import LoginActive from '../assets/UAEPASS-login-Active.png';
import LoginPressed from '../assets/UAEPASS-login-Pressed.png';
import LoginFocus from '../assets/UAEPASS-login-Focus.png';
import LoginDisabled from '../assets/UAEPASS-login-Disabled.png';
import ArLoginActive from '../assets/AR-UAEPASS-login-Active.png';
import ArLoginFocus from '../assets/AR-UAEPASS-login-Focus.png';
import ArLoginDisabled from '../assets/AR-UAEPASS-login-Disabled.png';
import ArLoginPressed from '../assets/AR-UAEPASS-login-Pressed.png';
import BaseComponent from "./BaseComponent";

export const hostBaseUrl = window.location.protocol + '//' + window.location.hostname + (window.location.port ? `:${window.location.port}` : '');

export default function Login(props) {    
    const [buttonState, setButtonState] = useState(null);     
    const language = StaticStorage().getItem("lang");
    let isArabic = language === 'ar';
    const loginClicked = (event) => {
        setButtonState('disabled');      
        const baseUrl = StaticStorage().getItem("uaepass_base_url");   
        const clientId = StaticStorage().getItem("uaepass_client_id");   
        const appBaseUrl = hostBaseUrl;
        if(baseUrl){
            let url = `${baseUrl}/idshub/authorize?response_type=code&client_id=${clientId}&scope=urn:uae:digitalid:profile:general urn:uae:digitalid:profile:general:profileType urn:uae:digitalid:profile:general:unifiedId&state=HnlHOJTkTb66Y5H&redirect_uri=${appBaseUrl}/oauth/redirect&acr_values=urn:safelayer:tws:policies:authentication:level:low`;                        
            window.location.href = url;
        }   
        else{
            alert("error");
        }     
    }
    const modifyButtonState = (state) => (event) => {
        if (buttonState === 'disabled')
            return;
        if (state === 'pressed') {
            loginClicked(event);
            return;
        }
        setButtonState(state);
    }
    const getStatusText = (text) => {
        return AxiosRequest.getStatusText(text);
    }
    return (
        <BaseComponent>
            <div id="Container">
                    <p style={{textAlign: 'center', margin: '15px', fontSize: 20}}>{getStatusText('loginTitle')}</p>
                    <span id={buttonState === 'disabled' ? "button-container-disabled" : "button-container"} onMouseLeave={modifyButtonState('active')} onFocus={modifyButtonState('focus')} onMouseDown={modifyButtonState('clicked')} onMouseUp={modifyButtonState('pressed')} onMouseEnter={modifyButtonState('focus')}>
                        {(buttonState === 'active' || !buttonState) && <img src={isArabic ? ArLoginActive : LoginActive} className="UAEPASS-button-style" />}
                        {buttonState === 'focus' && < img src={isArabic ? ArLoginFocus : LoginFocus} className="UAEPASS-button-style" />}
                        {buttonState === 'clicked' && <img src={isArabic ? ArLoginPressed : LoginPressed} className="UAEPASS-button-style" />}
                        {buttonState === 'disabled' && <img src={isArabic ? ArLoginDisabled : LoginDisabled} className="UAEPASS-button-style" />}
                    </span>
                </div>
        </BaseComponent>          
    );
}