import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './custom.css';
import Home, { Loader } from './components/Home';
import Redirection from './components/Redirection';
import { Beforeunload } from 'react-beforeunload';
import Login from './components/SignIn';
import axios from 'axios';
import BaseComponent from './components/BaseComponent';
import { useCookies } from 'react-cookie';
import AxiosRequest from './components/helpers/AxiosRequest';
import StaticStorage from './components/preferences/StaticStorage';
import { encode, decode } from 'js-base64';
import TokenRedirect from './components/TokenRedirect';
import Helpers from './components/helpers/Helpers';
import metadata from "./metadata.json";


export const auxButtonColor = '#205081';

export default function App() {
    const initializeRef = useRef(false);
    const [initialized, setInitialized] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [userMatching, setUserMatching] = useState(true);
    const [userType, setUserType] = useState(null);
    const [loading, setLoading] = useState(true);
    const [cookies, setCookie] = useCookies(['upsp']);
    const location = useLocation();
    useEffect(() => {
        initializeRef.current = initialized;
    }, [initialized]);
    useEffect(() => {
        document.title = `${metadata.appName} - UAE PASS Signature`
        window.addEventListener('message', (event) => {
            let payload = event?.data?.payload;
            if (payload) {
                let data = payload.app4legal_uaepass;
                if (data) {
                    Object.entries(data).forEach(entry => {
                        StaticStorage().setItem(entry[0], entry[1]);
                    });                    
                    saveToCookies();
                    setInitialized(true);                                            
                    checkUaePassStatus();
                    AxiosRequest.setAppDirection();
                }
            }
        })
        window.opener?.postMessage({
            is_app_loaded: true
        }, '*');
        setTimeout(() => {
            if (!initializeRef.current) {
                checkCookies();
            }
        }, 1000);
    }, []);
    const checkCookies = () => {
        let encodedData = cookies['upsp'];
        if (encodedData) {
            let decodedData = JSON.parse(decode(encodedData));
            StaticStorage().setAll(decodedData);
            AxiosRequest.setAppDirection();
            checkUaePassStatus();
        }
    }
    const checkUaePassStatus = () => {
        if (location.pathname?.includes("oauth/redirect")) {
            setLoading(false);
            setAuthenticated(true);
        }
        else {
            //Try to get UAE PASS access token
            axios.get('/signature/uaepass/accessToken')
                .then(response => {
                    //Access token valid
                    let token = response?.data?.accessToken;
                    if (token) {
                        //Get user information
                        let formData = Helpers.initiateFormData();
                        formData.append("accessToken", token);
                        axios.post('/signature/uaepass/getUser', formData)
                            .then(userResponse => {
                                let userInfo = userResponse.data;                  

                                setLoading(false);
                                checkUserValidity(userInfo)

                                // //Get data from saved preferences for internal user
                                // if (Helpers.isInternalUser()) {
                                //     //Check if non permitted account or different email                                
                                //     AxiosRequest.getUserPreferences()
                                //         .then(savedPreferences => {
                                //             setLoading(false);
                                //             if (Array.isArray(savedPreferences)) {
                                //                 if (savedPreferences.length == 0) {
                                //                     //No saved preferences
                                //                     checkUserValidity(userInfo)
                                //                 }
                                //                 else {
                                //                     let uaePassKeyName = savedPreferences[0].keyName;
                                //                     let uaePassKeyValue = savedPreferences[0].keyValue;
                                //                     if (uaePassKeyName === 'uaepass') {
                                //                         //Check if same user saved in core and server
                                //                         let savedUserInfo = JSON.parse(uaePassKeyValue);
                                //                         if (savedUserInfo.uuid === userInfo.uuid) {
                                //                             //same user
                                //                             //continue for signing
                                //                             setAuthenticated(true);
                                //                         }
                                //                         else {
                                //                             //User UUID not matching
                                //                             checkUserValidity(userInfo)
                                //                         }
                                //                     }
                                //                     else {
                                //                         console.log('App name: ' + uaePassKeyName);
                                //                         setUserNotLoggedIn();
                                //                     }
                                //                 }
                                //             }
                                //             else {
                                //                 //Error in API response
                                //                 console.log('API Response: ' + JSON.stringify(savedPreferences));
                                //                 setUserNotLoggedIn();
                                //             }
                                //         })
                                //         .catch(error => {
                                //             setUserNotLoggedIn();
                                //         })
                                // }
                                // else {
                                //     //For external and CP users
                                //     setLoading(false);
                                //     checkUserValidity(userInfo, false);
                                // }                               
                            })
                            .catch(error => {                                
                                //Error fetching user info
                                let errorResponse = error?.response?.data;
                                if(errorResponse === 'invalidToken'){
                                    //Invalid token
                                    axios.get("signature/uaepass/logout")
                                    .then(response => {
                                        window.location.reload();
                                    })
                                }
                                else{
                                    setUserNotLoggedIn();
                                }
                                console.error(error);
                            })
                    }
                    else {
                        //no token!
                        console.log('No token');
                        setUserNotLoggedIn();
                    }
                })
                .catch(error => {
                    //No access token or expired
                    setUserNotLoggedIn();                    
                })
        }
    }
    const checkUserValidity = (data) => {

        //Check if same user email and valid account type
        //If yes then save user information in the system
        const userEmail = data?.email;
        if (!userEmail) {
            setUserNotLoggedIn();
            return;
        }
        //Check user account type
        const accountType = data?.userType;
        if (accountType === 'SOP1') {
            setUserType(accountType);
            setUserNotLoggedIn();
            setUserMatching(false);
            return;
        }

        if (Helpers.checkIfUserNotMatching(data)) {
            setUserNotLoggedIn();
            setUserMatching(false);
        }        
        else{
            setAuthenticated(true);        
        }        
    }
    const setUserNotLoggedIn = () => {
        setLoading(false);
        setAuthenticated(false);
    }
    const saveToCookies = () => {
        let data = StaticStorage().getAll();
        let encodedData = encode(JSON.stringify(data));
        setCookie('upsp', encodedData, { path: '/', secure: true, sameSite: 'none' });
    }
    return (
        loading ? <BaseComponent>
            <Loader />
        </BaseComponent> :
            authenticated ?
                <Beforeunload onBeforeunload={(event) => {
                    let progressDone = sessionStorage?.getItem("d-uP");
                    if (progressDone === "1") {

                    }
                    else {
                        event.preventDefault();
                    }
                }}>
                    <Routes>
                        <Route index={true} element={<Home />} />
                        <Route path="/redirection" element={<Redirection />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/oauth/redirect" element={<TokenRedirect />} />
                    </Routes>
                </Beforeunload >
                :
                userMatching || userType == "SOP3" ?
                    <Login /> :
                    <TokenRedirect userType={userType} userMatching={false} />
    );
}
