/* global Excel, Office */

import StaticStorage from "./StaticStorage";
import { maxFileCount } from "./Config";
import metadata from "../../metadata.json";
const UIString= {
    // JSON object for English strings
    EN:
    {
        'signatureNotFound': 'No signature found!',
        'logout': 'Logout',
        "startOver": "Start over",
        "documentSigningFailed": "There was an error while fetching your documents from UAE PASS. Please retry",
        "userCancelProcess": "User cancelled the signing process",
        "digitalSignatureRestriction": "Please upgrade your account following instructions in the UAE PASS application",
        "unverifiedUserRestriction": "You are not eligible to access this service. Your account is either not upgraded or you have a visitor account. Please contact Dubai Police to access the services.",
        "backTo": "Back to",
        "cancelledByUserOnApp": "You have exited the notification request. Please restart the process.",
        "cancelledByUser": "User cancelled the login",
        'usersNotMatching': `Authentication failed! Invalid UAE PASS Email or Mobile are used.`,
        'invalidEmail': "Email not valid!",
        'invalidUser': `${metadata.appName} user not valid!`,
        'loginTitle': 'Please sign in with your UAE PASS account to proceed with the signature process.',
        "operationDone": "Operation Done! This window will be closed automatically",
        "transactionNotCompleted": "Transaction still not completed",
        "docsUploadError": "Problems in documents signature",
        'sessionExpired': 'Session expired!',
        'uploadingDocs': `Uploading signed document(s) to ${metadata.appName}...`,
        'checkStatus': 'Checking status...',
        'sendingDocs': 'Sending document(s) to UAE PASS...',
        'gettingReady': 'Getting document(s) ready...',
        'authentication': 'Redirecting to UAE PASS gateway...',
        'error': 'Something went wrong during the login, please try again later!',
        'change': 'CHANGE',
        'saveDocumentTo': 'The document(s) will be saved to:',
        'documentNotes': 'Document notes',
        'hideNotes': "Hide notes",
        'showNotes': "Show notes",
        "fileCountExceeded": `The maximum allowed number of files per upload is ${maxFileCount}.`,
        "canceled": 'Operation aborted',
        "fileName": "File name",
        'notAllowed': 'You are not allowed to perform this action',
        'notAvailable': 'Service is not available, please try again later!',
        'more': 'more',
        "yes": "Yes",
        "no": "No",
        'sign': "Sign",
        "save": "Save",
        "cancel": "Cancel",
        "loading": "Loading",
        'success': 'Success',
        'open': 'Open',
        'search': 'Search',
        "emailAddress": "Email Address",
        "password": "Password",
        "signin": "Sign In",
        'login': 'Login',
        'or': "OR",                
    },   
    // JSON object for Arabic strings
    AR:
    {
        'signatureNotFound': 'لم يتم العثور على أي إمضاء!',
        'logout': 'تسجيل الخروج',
        "startOver": "إعادة التوقيع",
        "documentSigningFailed": "حدث خطأ خلال مشاركة المستندات عن طريق الهوية الرقمية, يرجى إعادة المحاولة",
        "userCancelProcess": "قام المستخدم بإلغاء عملية التوقيع",
        "digitalSignatureRestriction": "يرجى ترقية حسابك باتباع الإرشادات الموجودة في تطبيق الهوية الرقمية",
        "unverifiedUserRestriction": "أنت غير مؤهل للوصول إلى هذه الخدمة. إما أن حسابك لم تتم ترقيته أو لديك حساب زائر. يرجى الاتصال بشرطة دبي لتتمكن من الوصول إلى الخدمة.",
        "backTo": "الرجوع إلى",
        "cancelledByUserOnApp": "لقد خرجت من طلب الإشعار، يرجى إعادة المحاولة مرة أخرى.",
        "cancelledByUser": "قام المستخدم بإلغاء تسجيل الدخول",
        'usersNotMatching': `المصادقة فشلت! تم استخدام بريد إلكتروني أو رقم هاتف محمول غير صالح لـ UAE PASS.`,
        'invalidEmail': "البريد الإلكتروني غير صالح!",
        'invalidUser': `مستخدم ${metadata.appName} غير صحيح!`,
        'loginTitle': "يرجى تسجيل الدخول باستخدام حساب UAE PASS الخاص بك لمتابعة عملية التوقيع.",
        "operationDone": "تمت العملية بنجاح! سيتم إغلاق هذه النافذة تلقائيًا",
        "transactionNotCompleted": "التحويل لا يزال غير مكتمل",
        "docsUploadError": "مشاكل في توقيع المستندات",
        'sessionExpired': 'انتهت الجلسة!',
        'uploadingDocs': `تحميل المستندات الموقعة إلى ${metadata.appName} ...`,
        'checkStatus': 'جارٍ التحقق من الحالة ...',
        'sendingDocs': 'إرسال المستندات إلى UAE PASS ...',
        'gettingReady': 'جاري تجهيز المستندات ...',
        'authentication': 'إعادة التوجيه إلى بوابة UAE PASS ...',
        'error': 'حدث خطأ ما أثناء تسجيل الدخول، يرجى المحاولة مرة أخرى لاحقًا!',
        "loading": "قيد المعالجة...",
        'change': 'تغيير',        
        "canceled": 'تم توقيف العملية',
        "fileName": "اسم الملف",
        'downloading': 'جاري التحميل',
        "yes": "نعم",
        "no": "كلا",
    }
};
export const getText = (key) => {
    let locale = getLocaleStrings();
    if(locale[key]){
        return locale[key];
    }
    else{
        return key;
    }    
}
export const getLocaleStrings = () => {
    var text;
    let locale = StaticStorage().getItem("lang") ?? "en";
    // Get the resource strings that match the language.
    switch (locale) {
        case 'en': case 'english':
            text = UIString.EN;
            break;
        case 'sp': case 'es': case 'spanish':
            text = UIString.SP;
            break;
        case 'fr': case 'french':
            text = UIString.FR;
            break;
        case 'ar': case 'arabic':
            text = UIString.AR;
            break;
        default:
            text = UIString.EN;
            break;
    }
    return text;
}