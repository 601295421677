import axios from "axios";
import React, { useState, useEffect } from "react";
import StaticStorage from './preferences/StaticStorage';
import AxiosRequest from "./helpers/AxiosRequest";
import '../custom.css';
import BaseComponent from "./BaseComponent";
import Helpers from "./helpers/Helpers";
import { hostBaseUrl } from "./SignIn";
import Logout from "./SignOut";
import BackToAppButton from "./BackToAppButton";

export default function TokenRedirect(props) {
    const [status, setStatus] = useState("loading");
    useEffect(() => {
        if (props.userType) {
            const isPermitted = checkIfPermittedAccount(props.userType);
            if (!isPermitted) {
                return;
            }
        }
        if (props.userMatching === false) {
            setStatus("usersNotMatching")
            return;
        }
        //Check for errors
        const error = Helpers.getUrlPar("error");
        if (error) {
            const errorDescription = Helpers.getUrlPar("error_description")?.toLowerCase();
            switch (error) {
                case 'invalid_request': {
                    //Get error description
                    if (errorDescription) {
                        if (errorDescription.includes("cancelled+by+user")) {
                            setStatus("cancelledByUser");
                            return
                        }
                    }
                } break;
                case "access_denied": {
                    //Get error description
                    if (errorDescription) {
                        if (errorDescription.includes("cancelled+by+user+on+uae+pass+app")) {
                            setStatus("cancelledByUserOnApp");
                            return
                        }
                    }
                } break;
            }
        }
        //Get Access Code
        const accessCode = Helpers.getUrlPar("code");
        if (!accessCode || accessCode?.length == 0) {
            //Invalid code
            setStatus("error");
        }
        else {
            //Try to get access token            

            //Get user email from App4Legal
            const email = StaticStorage().getItem("email");
            if (!email) {
                setStatus("invalidUser");
                return;
            }

            var formData = new FormData();
            formData = Helpers.initiateFormData(formData);
            formData.append('code', accessCode);

            axios.post('/signature/uaepass/initiateUser', formData)
                .then(response => {
                    const data = response?.data;
                    if (data) {
                        checkUserValidity(data);
                        // //Check if UUID saved already
                        // AxiosRequest.getUserPreferences()
                        //     .then(savedPreferences => {
                        //         if (Array.isArray(savedPreferences)) {
                        //             if (savedPreferences.length == 0) {
                        //                 //No saved preferences
                        //                 checkUserValidity(data);
                        //             }
                        //             else {
                        //                 let uaePassKeyName = savedPreferences[0].keyName;
                        //                 let uaePassKeyValue = savedPreferences[0].keyValue;
                        //                 if (uaePassKeyName === 'uaepass') {
                        //                     //Check if same user saved in core and server
                        //                     let savedUserInfo = JSON.parse(uaePassKeyValue);
                        //                     let uuid = savedUserInfo.uuid;
                        //                     if (uuid === data.uuid) {
                        //                         //Same user => no need to check email
                        //                         window.location.href = hostBaseUrl;
                        //                     }
                        //                     else {
                        //                         //Not same UUID 
                        //                         checkUserValidity(data);                                                                                               
                        //                     }
                        //                 }
                        //                 else {
                        //                     console.log('App name: ' + uaePassKeyName);                                            
                        //                     checkUserValidity(data);
                        //                 }                                               
                        //             }
                        //         }    
                        //         else{
                        //             //Error in API response
                        //             console.log('API Response: ' + JSON.stringify(savedPreferences));
                        //             checkUserValidity(data);
                        //         }                                  
                        //     })
                        //     .catch(error => {
                        //         //Not saved
                        //         checkUserValidity(data);
                        //     })
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }, []);
    const checkUserValidity = (data) => {        

        //Check if same user email and valid account type
        //If yes then save user information in the system
        const userEmail = data?.email;
        if (!userEmail) {
            setStatus("invalidEmail");
            return;
        }
        //Check user account type
        const accountType = data?.userType;
        const isPermitted = checkIfPermittedAccount(accountType);
        if (!isPermitted) {
            return;
        }

        if (Helpers.checkIfUserNotMatching(data)) {
            setStatus("usersNotMatching");
        }
        else {
            //Check if user is internal, if yes then save info to preferences
            if (Helpers.isInternalUser()) {
                //Saving user information in the system
                AxiosRequest.saveUserPreferences(data)
                    .then(response => {
                        // Return to signing process
                        window.location.href = hostBaseUrl;
                    })
                    .catch(error => {
                        console.error(error);
                        setStatus("error");
                    })
            }
            else {
                // Return to signing process
                window.location.href = hostBaseUrl;
            }
        }
    }
    const getStatusText = (text) => {
        return AxiosRequest.getStatusText(text);
    }
    const checkIfPermittedAccount = (accountType) => {
        if (accountType) {
            switch (accountType) {
                case 'SOP1': {
                    //Unverified user restriction
                    setStatus("unverifiedUserRestriction")
                    return false;
                }
                default: return true;
            }
        }
        return false;
    }
    const checkIfBackButtonEnabled = () => {
        switch (status) {
            case 'error':
            case 'cancelledByUser':
            case 'cancelledByUserOnApp':
            case 'unverifiedUserRestriction':
            case 'digitalSignatureRestriction':
                return true;
            default: return false;
        }
    }
    const checkIfLogoutButtonEnabled = () => {
        if (status === "usersNotMatching" ||
            status === "unverifiedUserRestriction")
            return true;
        return false;
    }
    return (
        <BaseComponent>
            <div id="Container">
                <p style={{ textAlign: 'center', margin: '15px', fontSize: 20 }}>{getStatusText(status)}</p>
                {checkIfLogoutButtonEnabled() && <Logout />}
                {checkIfBackButtonEnabled() && <BackToAppButton/>}
            </div>
        </BaseComponent>
    );
}