import React, { useState } from "react";
import StaticStorage from './preferences/StaticStorage';
import AxiosRequest from "./helpers/AxiosRequest";
import '../custom.css';
import axios from "axios";
import { hostBaseUrl } from "./SignIn";
import Helpers from "./helpers/Helpers";

export default function Logout() {    
    const getStatusText = (text) => {
        return AxiosRequest.getStatusText(text);
    }
    const handleLogout = async (event) => {
        Helpers.removeCloseCheck();
        await axios.get("signature/uaepass/logout");
        try {
            if (Helpers.isInternalUser())
                await AxiosRequest.deleteUserPreferences();
        }
        catch (err) {

        }
        const baseUrl = StaticStorage().getItem("uaepass_base_url");
        window.location.href = `${baseUrl}/idshub/logout?redirect_uri=${hostBaseUrl}`
    }
    return (
        <button style={{fontWeight: 500}} onClick={handleLogout}>{getStatusText("logout")}</button>         
    );
}